import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from 'flowbite-react';
import axios from 'axios';



function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


function renderProductRows(data) {
  return data.map((product, index) => (
    <TableRow key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
      {Object.keys(product).map((key) => (
        <TableCell 
          key={key} 
          className="text-xs whitespace-normal font-medium text-gray-900 dark:text-white"
        >
          {product[key]}
        </TableCell>
      ))}
    </TableRow>
  ));
}

const stickyHeaderStyle = {
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  zIndex: 1,
};







// Function to calculate range of pages
function paginationRange(current, total) {
  let range = [];
  if (total <= 5) {
    // Less than 5 total pages, so show all
    range = [...Array(total).keys()].map(x => x + 1);
  } else {
    // More than 5 total pages
    if (current <= 3) {
      // Current page near the start
      range = [1, 2, 3, 4, -1, total];
    } else if (current >= total - 2) {
      // Current page near the end
      range = [1, -1, total - 3, total - 2, total - 1, total];
    } else {
      // Current page somewhere in the middle
      range = [1, -1, current - 1, current, current + 1, -1, total];
    }
  }
  return range;
}


// Helper function to generate a range of numbers
function range(start, end) {
  return Array.from({ length: end - start + 1 }, (_, i) => i + start);
}


function EPandlogDB() {
  const [filters, setFilters] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState('species');
  const [filterValue, setFilterValue] = useState('');
  const [selectedOperator, setSelectedOperator] = useState('=');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchResults, setSearchResults] = useState([]); // Initialize as empty array
  const [columnOptions, setColumnOptions] = useState([]); // New state to track column options
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('ascending'); // Or 'descending'
  
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const response = await axios.get(`${backendUrl}/retrievePangloDB`);
        setSearchResults(response.data);
        if (response.data.length > 0) {
          const columns = Object.keys(response.data[0]);
          setColumnOptions(columns); // Set column options based on fetched data
          setSelectedColumn(columns[0]); // Default to the first column
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
  }, []);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
    } else {
      setSortColumn(column);
      setSortDirection('ascending');
    }
  };

  const handleAddFilter = () => {
    const newFilter = { column: selectedColumn, operator: selectedOperator, value: filterValue };
    setFilters((currentFilters) => [...currentFilters, newFilter]);
    setFilterValue('');
  };

  const handleSearch = () => {
    const filteredResults = searchResults.filter(product =>
      filters.every(filter => {
        const productValue = product[filter.column];
  
        // Normalize string comparisons to lowercase for case-insensitive matching
        if (typeof productValue === 'string' && filter.operator === '=') {
          return productValue.toLowerCase() === filter.value.toLowerCase();
        } else if (!isNaN(productValue)) { // Handle numeric comparisons
          const filterValNum = parseFloat(filter.value);
          if (isNaN(filterValNum)) {
            console.error('Filter value is not a valid number for operator', filter.operator);
            return false; // Filter value isn't a number for a numeric comparison
          }
  
          switch (filter.operator) {
            case '>':
              return productValue > filterValNum;
            case '<':
              return productValue < filterValNum;
            case '=':
              return productValue === filterValNum;
            default:
              return false; // Unhandled operator
          }
        } else {
          // Fallback for any other unexpected data types or conditions
          return false;
        }
      })
    );
    setSearchResults(filteredResults);
  };
  
  
  const sortData = (data, column, direction) => {
    if (!column) return data; // If no sort column is set, return the data as is
  
    return [...data].sort((a, b) => {
      if (a[column] < b[column]) return direction === 'ascending' ? -1 : 1;
      if (a[column] > b[column]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };
  

    // Sorting logic moved here to be applied before slicing for pagination
    const sortedData = sortData(searchResults, sortColumn, sortDirection);

    const lastItemIndex = currentPage * itemsPerPage;
    const firstItemIndex = lastItemIndex - itemsPerPage;
    
    // Use sortedData to calculate currentItems for display
    const currentItems = sortedData.slice(firstItemIndex, lastItemIndex);
    
    const totalPages = Math.ceil(searchResults.length / itemsPerPage);


  const handleReset = () => {
    setFilters([]);
    setFilterValue('');
    // Fetch data again or reset to initial fetched data if not overwritten
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Function to update the current page
  };



  function renderTableHeaders(data) {
    if (data.length > 0) {
      return Object.keys(data[0]).map((key) => (
        <TableHeadCell 
          key={key} 
          // Update the style here to include backgroundColor and color
          style={{ 
            ...stickyHeaderStyle, 
            cursor: 'pointer',
            backgroundColor: 'gray', // or 'black' for a black background
            color: 'white', // White text color for better contrast
          }} 
          className="text-xs whitespace-normal font-bold"
          onClick={() => handleSort(key)}
        >
          {capitalizeFirstLetter(key.replace(/_/g, ' '))}
          {sortColumn === key && (sortDirection === 'ascending' ? ' ↑' : ' ↓')}
        </TableHeadCell>
      ));
    }
    return null;
  }
  
// Then in your component
const pageNumbers = paginationRange(currentPage, totalPages);

const [goToPage, setGoToPage] = useState('');

// Handler to jump to the page entered by the user
const handleGoToPage = (e) => {
  e.preventDefault(); // Prevent default form submission behavior
  const pageNumber = Number(goToPage);
  if (pageNumber >= 1 && pageNumber <= totalPages && Number.isInteger(pageNumber)) {
    setCurrentPage(pageNumber);
    setGoToPage(''); // Clear the input field after jumping to the page
  }
};
  
  return (
    <div className="flex flex-col items-center justify-center">
      {/* UI elements for filters, add, reset, and search buttons */}
      <div className="mb-4">
        {/* Filters and buttons */}
        
        <h2 className="mb-2 mt-5 text-lg font-semibold text-gray-900 dark:text-white">Filters:</h2>
        {filters.length > 0 ? (
          <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
            {filters.map((filter, index) => (
              <li key={index}>
                {capitalizeFirstLetter(filter.column)} = {filter.value}
              </li>
            ))}
          </ul>
        ) : (
          <p>No filters added</p>
        )}
        <select
          value={selectedColumn}
          onChange={(e) => setSelectedColumn(e.target.value)}
          className="mt-5 mb-5 text-sm border rounded-lg p-2 dark:bg-gray-700 dark:text-white"
        >
          {columnOptions.map((key) => (
            <option key={key} value={key}>{capitalizeFirstLetter(key.replace(/([A-Z])/g, ' $1'))}</option>
          ))}
        </select>
        <select
          value={selectedOperator}
          onChange={(e) => setSelectedOperator(e.target.value)}
          className="text-sm border rounded-lg p-2 dark:bg-gray-700 dark:text-white mx-2"
        >
          <option value="=">equals</option>
          <option value=">">greater than</option>
          <option value="<">less than</option>
        </select>
        <input
          type="text"
          placeholder="Enter filter value"
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          className="text-sm border rounded-lg p-2 dark:bg-gray-700 dark:text-white"
        />
        <button onClick={handleAddFilter} className="p-2 bg-blue-500 text-white rounded-lg">Add</button>
        <button onClick={handleReset} className="ml-2 p-2 bg-red-500 text-white rounded-lg">Reset</button>
        <button onClick={handleSearch} className="ml-2 p-2 bg-green-500 text-white rounded-lg">Search</button>
      </div>
      {/* Table rendering */}
      <div className="w-full max-w-7xl bg-white shadow-md rounded-lg overflow-hidden">
        <div className="max-w-4xl min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          {/* Set a specific height and make it scrollable */}
          <div style={{ height: '500px', overflowY: 'auto' }}>
            <Table>
              <TableHead>{renderTableHeaders(searchResults)}</TableHead>
              <TableBody className="divide-y">{renderProductRows(currentItems)}</TableBody>
            </Table>
          </div>
        </div>
      </div>       
      {/* Pagination Controls */}
      <div className="mt-4 flex justify-center items-center">
        {pageNumbers.map((page, index) => {
          if (page === -1) return <div key={index}>...</div>;
          if (page === -2) return <div key={index}>...</div>;
          return (
            <button
              key={index}
              onClick={() => handlePageChange(page)}
              className={`px-4 py-2 mx-1 ${currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              {page}
            </button>
          );
        })}

        <form onSubmit={handleGoToPage} className="flex items-center">
            <input
              type="number"
              min="1"
              max={totalPages}
              value={goToPage}
              onChange={(e) => setGoToPage(e.target.value)}
              className="text-sm border rounded-lg p-2 dark:bg-gray-700 dark:text-white mx-2"
              placeholder={`Page (1-${totalPages})`}
            />
            <button type="submit" className="p-2 bg-blue-500 text-white rounded-lg">
              Go
            </button>
          </form>


      </div>
      <p className="mt-5 text-gray-500 dark:text-gray-400">Showing {searchResults.length} results</p> 
      <p className="mt-5 text-gray-500 dark:text-gray-400">Reference: https://panglaodb.se </p> 
    </div>
  );
}

export default EPandlogDB;