import React from 'react';

function ServicesSection() {
  // Services information
  const services = [
    {
      name: 'PangloDB',
      description: 'Allow users to search gene markers to find most related cell type',
    },
    {
      name: 'Scanpy',
      description: 'Integrated system',
    },
    {
      name: 'Text Embeddings',
      description: 'Finding most related cell type based on user input',
    },
  ];

  return (
    <section className="py-12"> {/* Updated classes here */}
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-extrabold text-center text-gray-800 mb-8">
          Functionalities 
        </h2>
        <p className="text-lg text-center text-gray-900 mb-12">
          Current, In Progress and Future functionalities of the microservices. 
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 ">
          {services.map((service, index) => (
            <div key={index} className="bg-custom-purple rounded-lg shadow p-8">
            <h3 className="text-2xl font-bold text-gray-100 mb-4">{service.name}</h3>
              <p className="text-sm text-white font-bold">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ServicesSection;
