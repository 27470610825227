// AccordionItem component for reusability and cleaner code
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AccordionItem = ({ id, question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };
   
    return (
      <div className="mb-2 last:mb-0">
        <button 
          type="button" 
          className="flex items-center justify-between w-full py-4 px-6 text-left text-gray-700 bg-gray-300 rounded-lg shadow focus:outline-none"
          onClick={toggleAccordion}
          aria-expanded={isOpen}
          aria-controls={`accordion-content-${id}`}
        >
            
          <span className="text-gray-900 font-bold">{question}</span>
          <svg 
            className={`w-6 h-6 transform transition-transform ${isOpen ? 'rotate-0' : '-rotate-180'}`}
            viewBox="0 0 24 24" 
            fill="none"
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <polyline points="18 15 12 9 6 15"></polyline>
          </svg>
        </button>
        <div
          id={`accordion-content-${id}`}
          className={`transition-max-height duration-500 ease-in-out ${isOpen ? 'max-h-96' : 'max-h-0'} overflow-hidden`}
          aria-labelledby={`accordion-header-${id}`}
        >
          <div className="text-white font-bold py-4 px-6">
            {answer}
          </div>
        </div>
      </div>
    );
  };


  const EFAQ = () => {
    const [faqs, setFaqs] = useState([]);
  
    // Set the backend URL
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8030';
  
    useEffect(() => {
      const fetchFaqs = async () => {
        try {
          const response = await axios.get(`${backendUrl}/faq_displayer/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`, // If authorization is required
            },
          });
          setFaqs(response.data); // No need to map if the data is already in the correct format
        } catch (error) {
          console.error('Error fetching FAQs:', error);
        }
      };
  
      fetchFaqs();
    }, [backendUrl]); // If backendUrl is stable, it doesn't need to be a dependency
  
    return (
      <div className="flex flex-col items-center justify-center py-8">
        <div className="w-full max-w-2xl bg-custom-purple rounded-xl shadow-lg p-6">
          {faqs.length > 0 ? (
            faqs.map((faq) => (
              <AccordionItem key={faq.index} question={faq.title} answer={faq.answer} />
            ))
          ) : (
            <p>Loading FAQs...</p> // Display a loading state or message
          )}
        </div>
      </div>
    );
  };
  
  export default EFAQ;
  