import React from 'react';


const FHomepage = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8030';


  return (
    <div id="main-wrapper" className="flex flex-col items-center justify-center ">
      <div className="text-center mt-10">
        <h1 className="text-3xl font-bold text-gray-900">Welcome</h1>
        <p className="mt-3 text font-bold text-gray-800">to the</p> 
        <h1 className="text-3xl font-bold text-gray-900 m-2">iThesis Microservices</h1>
        <h1 className="text-3xl font-bold text-gray-900 mt-5"> my current backend server: {backendUrl} </h1> 

        <p className="mt-3 text-lg text-gray-900">This web application is built to host various microservices.</p>
        <p className="mt-3 text text-gray-900">You can check the FAQ section.</p> 
      </div>
      
  
      <div className="mt-4 flex justify-center items-center w-full"> {/* Centered container */}
        
      </div>  
    </div>
  );
};

export default FHomepage;


// import myLogoStripe  from '../../images/stripe.webp'; // Make sure this path is correct for your logo
// <img src={myLogoStripe} className="h-6 sm:h-9 mx-1" alt="Stripe Logo" />