import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Navbar } from 'flowbite-react';
import '../../css/Default/NavigationBar.css'; 
import myLogo from '../../images/bilim.png'; // Make sure this path is correct for your logo

function ENavigationBar() {
  //const navigate = useNavigate();


  return (

  <Navbar fluid={true} rounded={true} className="navbar-custom">
      <Navbar.Brand href="/">
        <Link to="/" className="flex items-center">
          {<img src={myLogo} className="mr-3 h-6 sm:h-9" alt="My Brand Logo"/>}  
          <span className="self-center text-xl font-semibold whitespace-nowrap text-white">Thesis Microservices</span>
        </Link>
      </Navbar.Brand>
      <div className="flex md:order-2 mr-4 md:mr-8 lg:mr-5"> 
        
        <Link to="/" className="px-4 py-2 text-sm text-white font-bold">Home</Link>
        <Link to="/FAQ" className="px-4 py-2 text-sm text-white font-bold">FAQ</Link>
        <Link to="/Microservices" className="px-4 py-2 text-sm text-white font-bold">Microservices</Link>
        <Link to="/PangloDB" className="px-4 py-2 text-sm text-white font-bold">PangloDB</Link> 
        <Link to="/StaticTest" className="px-4 py-2 text-sm text-white font-bold">Static Display</Link> 
        <Link to="/DynamicTest" className="px-4 py-2 text-sm text-white font-bold">Dynamic Display</Link> 
        
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>

      </Navbar.Collapse>
    </Navbar>
  );
}

export default ENavigationBar;
