import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from 'flowbite-react';
import axios from 'axios';



function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


function renderProductRows(data) {
  return data.map((product, index) => (
    <TableRow key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
      {Object.keys(product).map((key) => (
        <TableCell key={key} className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {product[key]}
        </TableCell>
      ))}
    </TableRow>
  ));
}

function renderTableHeaders(data) {
  if (data.length > 0) {
    return Object.keys(data[0]).map((key) => (
      <TableHeadCell key={key}>
        {capitalizeFirstLetter(key.replace(/([A-Z])/g, ' $1'))}
      </TableHeadCell>
    ));
  }
  return null;
}














function EDynamicDisplay() {
  const [filters, setFilters] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState('productName');
  const [filterValue, setFilterValue] = useState('');
  const [selectedOperator, setSelectedOperator] = useState('=');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchResults, setSearchResults] = useState([]); // Initialize as empty array
  const [columnOptions, setColumnOptions] = useState([]); // New state to track column options

  useEffect(() => {
    const fetchData = async () => {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const response = await axios.get(`${backendUrl}/artifical`);
        setSearchResults(response.data);
        if (response.data.length > 0) {
          const columns = Object.keys(response.data[0]);
          setColumnOptions(columns); // Set column options based on fetched data
          setSelectedColumn(columns[0]); // Default to the first column
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
  }, []);


  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentItems = searchResults.slice(firstItemIndex, lastItemIndex); // Use sliced data for pagination

  const totalPages = Math.ceil(searchResults.length / itemsPerPage);

  const handleAddFilter = () => {
    const newFilter = { column: selectedColumn, operator: selectedOperator, value: filterValue };
    setFilters((currentFilters) => [...currentFilters, newFilter]);
    setFilterValue('');
  };

  const applyFilter = (product, filter) => {
    const productValue = product[filter.column]?.toString().toLowerCase();
    const filterValue = filter.value.toLowerCase();
    switch (filter.operator) {
      case '>':
        return parseFloat(product[filter.column]) > parseFloat(filter.value);
      case '<':
        return parseFloat(product[filter.column]) < parseFloat(filter.value);
      case '=':
      default:
        return productValue.includes(filterValue);
    }
  };

  const handleSearch = () => {
    // Here, adjust the logic to correctly filter `searchResults` based on the new data structure
    const filteredResults = searchResults.filter(product => 
      filters.every(filter => {
        let productValue = String(product[filter.column]).toLowerCase();
        let filterVal = filter.value.toLowerCase();
        switch (filter.operator) {
          case '>':
            return Number(product[filter.column]) > Number(filter.value);
          case '<':
            return Number(product[filter.column]) < Number(filter.value);
          case '=':
          default:
            return productValue.includes(filterVal);
        }
      })
    );
    setSearchResults(filteredResults); // Be cautious, this will overwrite your fetched data
  };

  const handleReset = () => {
    setFilters([]);
    setFilterValue('');
    // Fetch data again or reset to initial fetched data if not overwritten
    // setSearchResults(productData); This needs to be adjusted based on your data handling logic
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Function to update the current page
  };



  return (
    <div className="flex flex-col items-center justify-center">
      {/* UI elements for filters, add, reset, and search buttons */}
      <div className="mb-4">
        {/* Filters and buttons */}
        
        <h2 className="mb-2 mt-5 text-lg font-semibold text-gray-900 dark:text-white">Filters:</h2>
        {filters.length > 0 ? (
          <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
            {filters.map((filter, index) => (
              <li key={index}>
                {capitalizeFirstLetter(filter.column)} = {filter.value}
              </li>
            ))}
          </ul>
        ) : (
          <p>No filters added</p>
        )}
        <select
          value={selectedColumn}
          onChange={(e) => setSelectedColumn(e.target.value)}
          className="mt-5 mb-5 text-sm border rounded-lg p-2 dark:bg-gray-700 dark:text-white"
        >
          {columnOptions.map((key) => (
            <option key={key} value={key}>{capitalizeFirstLetter(key.replace(/([A-Z])/g, ' $1'))}</option>
          ))}
        </select>
        <select
          value={selectedOperator}
          onChange={(e) => setSelectedOperator(e.target.value)}
          className="text-sm border rounded-lg p-2 dark:bg-gray-700 dark:text-white mx-2"
        >
          <option value="=">equals</option>
          <option value=">">greater than</option>
          <option value="<">less than</option>
        </select>
        <input
          type="text"
          placeholder="Enter filter value"
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          className="text-sm border rounded-lg p-2 dark:bg-gray-700 dark:text-white"
        />
        <button onClick={handleAddFilter} className="p-2 bg-blue-500 text-white rounded-lg">Add</button>
        <button onClick={handleReset} className="ml-2 p-2 bg-red-500 text-white rounded-lg">Reset</button>
        <button onClick={handleSearch} className="ml-2 p-2 bg-green-500 text-white rounded-lg">Search</button>
      </div>
      {/* Table rendering */}
      <div className="w-full max-w-4xl bg-white shadow-md rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Table>
              <TableHead>{renderTableHeaders(searchResults)}</TableHead>
              <TableBody className="divide-y">{renderProductRows(currentItems)}</TableBody>
            </Table>
          </div>
        </div>
      </div>
       {/* Pagination Controls */}
      <div className="mt-4 flex justify-center items-center">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`px-4 py-2 mx-1 ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>

      <p className="mt-5 text-gray-500 dark:text-gray-400">Showing {searchResults.length} results</p> 
      <p className="mt-5 text-gray-500 dark:text-gray-400">Reference: https://panglaodb.se </p> 
    </div>
  );
}

export default EDynamicDisplay;