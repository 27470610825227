// App.js
import React                                      from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../../css/Fundemental/App.css'; 


import FHomepage                     from '../Homepage/Homepage';

import ENavigationBar                from '../Default/NavigationBar';
import FFooter                       from '../Default/Footer'; 
import EPrivacyPolicy                from '../Default/PrivacyPolicy';  
import EFAQ                          from '../Default/FAQ'; 

import FMicroservices                from '../Microservices/ServicesMain'; // Adjust the path as necessary 
 
import EPandlogDB                     from '../PangloDB/PangloDisplay'; // Adjust the path as necessary 
import EStaticDisplay                           from '../PangloDB/StaticDisplay'; // Adjust the path as necessary 
import EDynamicDisplay                           from '../PangloDB/DynamicDisplay'; // Adjust the path as necessary 

const App = () => {
  return (
    <Router>
      <ENavigationBar />
      <div className="site-content">
        <Routes>
          <Route path="/" element={<FHomepage />} /> 

          <Route path="/Microservices" element={<FMicroservices />} /> 
          <Route path="/PrivacyPolicy" element={<EPrivacyPolicy />} /> 
          <Route path="/FAQ" element={<EFAQ />} /> 
          <Route path="/PangloDB" element={<EPandlogDB />} /> 
          <Route path="/StaticTest" element={<EStaticDisplay />} /> 
          <Route path="/DynamicTest" element={<EDynamicDisplay />} /> 


        </Routes>
      </div>
      <FFooter />
    </Router>
  );
};

export default App;